import * as React from 'react';
import { Link as RouterLink } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LogoutIcon from '@mui/icons-material/Logout';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FastForwardIcon from '@mui/icons-material/FastForward';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SecurityIcon from '@mui/icons-material/Security';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import PeopleIcon from '@mui/icons-material/People';
import RecommendIcon from '@mui/icons-material/Recommend';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import { BrandContext } from '../../App';

export function MainDashboardMenu() {
  const { user } = React.useContext(BrandContext);

  return (
  <React.Fragment>
    {user && (
      <>
    <ListSubheader component="div" inset>{user.company && user.company.name}</ListSubheader>
    <ListItemButton component={RouterLink} to="/dashboard/home">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/trainingprograms">
      <ListItemIcon>
        <PlayCircleFilledIcon />
      </ListItemIcon>
      <ListItemText primary="My Programs" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/simulations">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Past simulations" />
    </ListItemButton>
    <ListItemButton component={RouterLink} to="/dashboard/leaderboard">
      <ListItemIcon>
        <FormatListNumberedIcon />
      </ListItemIcon>
      <ListItemText primary="Leaderboard" />
    </ListItemButton>


    </>
    )}

  </React.Fragment>
  );
}

export function SecondaryDashboardMenu() {
  const { user } = React.useContext(BrandContext);

  return (
  <React.Fragment>
    <ListItemButton component={RouterLink} to="/dashboard/team">
      <ListItemIcon>
        <GroupsIcon />
      </ListItemIcon>
      <ListItemText primary="Your team" />
    </ListItemButton>
    {false && (
      <>
      <ListItemButton component={RouterLink} to="/dashboard/subscription">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Subscription" />
    </ListItemButton>
    </>
    )}
    <ListItemButton component={RouterLink} to="/dashboard/settings">
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>

    <ListItemButton component={RouterLink} to="/logout">
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Log out" />
    </ListItemButton>
  </React.Fragment>
);
}