import React from 'react';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Typography,
    Paper,
    Snackbar,

} from '@mui/material';
import { BrandContext } from '../../App';

import VrpanoIcon from '@mui/icons-material/Vrpano';
import GroupIcon from '@mui/icons-material/Group';

import { LineChart } from '@mui/x-charts/LineChart';
import Moment from 'moment';

import ReactPlayer from 'react-player'

import { CallScreen } from '../call-screen/CallScreen'

export default function DashboardCall() {
    const { brand, user, authToken } = React.useContext(BrandContext);
    const [simulationId, setSimulationId] = React.useState(null);
    const [scenarioData, setScenarioData] = React.useState(null);
    const [simulationHistory, setSimulationHistory] = React.useState(null);
    const [simulationLineChartData, setSimulationLineChartData] = React.useState(null);
    const [simulationCallURL, setSimulationCallURL] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [showCallScreen, setShowCallScreen] = React.useState(true);

    const { scenario_id } = useParams();
    const navigate = useNavigate();

    Moment.locale('en');

    function startScenario() {
        setLoading(true);

        fetch(`${brand.api_base_url}/start_simulation`, {
            headers: { 'Authorization': `Token ${authToken}` },
            method: 'POST',
            body: JSON.stringify({ 'scenario_id': scenario_id })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data['id']) {
                    setSimulationCallURL(data['webrtc_url']);
                    setSimulationId(data['id']);
                    setLoading(false);
                } else {
                    setSnackbarMessage('Starting simulation failed');
                    setLoading(false);
                }
            }).then()
            .catch((err) => {
                setSnackbarMessage('Starting simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }

    React.useEffect(() => {
        document.title = `Simulate - ${brand.name}`;
    }, [user]);

    React.useEffect(() => {
        fetch(`${brand.api_base_url}/scenario?scenario_id=${scenario_id}`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setScenarioData(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });

        fetch(`${brand.api_base_url}/simulations?scenario_id=${scenario_id}`, {
            headers: { 'Authorization': `Token ${authToken}` },
        })
            .then((response) => response.json())
            .then((data) => {
                setSimulationHistory(data);
                generateSimulationLineChartData(data);
            }).then()
            .catch((err) => {
                console.log(err.message);
            });

    }, [authToken, scenario_id]);

    function generateSimulationLineChartData(data) {
        var lcData = {
            eq_score: [],
            impact_score: [],
            super_influencer_score: [],
            dates: [],
        };
        var max_runs = 5;
        data.forEach((s) => {
            if (s.evaluation != null && max_runs > 0) {
                lcData.eq_score.push(s.evaluation.eq_score.value);
                lcData.impact_score.push(s.evaluation.impact_score.value);
                lcData.super_influencer_score.push(s.evaluation.super_influencer_score.value);
                lcData.dates.push(Moment(s.ended_at).format('H:m d MMM'));
                max_runs = max_runs - 1;
            }

        });
        setSimulationLineChartData(lcData);
    }

    function endSimulation() {
        setShowCallScreen(false);
        fetch(`${brand.api_base_url}/rtc_end_simulation`, {
            headers: { 'Authorization': `Token ${authToken}` },
            method: 'POST',
            body: JSON.stringify({ 'simulation_id': simulationId })
        })
            .then((response) => response.json())
            .then((data) => {
                window.location = '/dashboard/simulations';
            })
            .catch((err) => {
                setSnackbarMessage('Ending simulation failed');
                console.log(err.message);
                setLoading(false);
            });
    }


    return (
        <React.Fragment>
            <Snackbar
                open={snackbarMessage ? true : false}
                autoHideDuration={5000}
                onClose={() => { setSnackbarMessage(''); }}
                message={snackbarMessage}
            />
            {scenarioData != null && (
                <>
                    {simulationId == null ? (
                        <>
                            <Typography variant="h4">{scenarioData.name}</Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    {scenarioData.briefing_video && (
                                        <>
                                            <ReactPlayer
                                                url={scenarioData.briefing_video}
                                                controls={true}
                                                width='90%'
                                                style={{ margin: '2rem' }}
                                            />
                                        </>
                                    )}

                                    <Paper sx={{ m: 2, p: 2 }}>
                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{scenarioData.long_description}</Typography>
                                    </Paper>
                                    <Button onClick={startScenario} variant="contained" size="large" sx={{ m: 2 }} startIcon={<GroupIcon />}>Start Simulation</Button>
                                    <Button variant="contained" size="large" sx={{ m: 2 }} disabled={true} startIcon={<VrpanoIcon />}>Start Simulation in VR</Button>
                                    <Box
                                        sx={{
                                            border: "1px solid #ccc",
                                            padding: "1rem",
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        {scenarioData.collateral_type?.includes("image") && (
                                            <img src={scenarioData.collateral_resource} alt="Content" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                                        )}
                                        {scenarioData.collateral_type?.includes("pdf") && (
                                            <iframe
                                                src={scenarioData.collateral_resource}
                                                type="application/pdf"
                                                width="100%"
                                                height="500px"
                                                style={{ border: "none" }}
                                            />
                                        )}
                                        {scenarioData.collateral_type?.includes("video") && (
                                            <video controls width="100%">
                                                <source src={scenarioData.collateral_resource} type={scenarioData.collateral_type} />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {!["image", "pdf", "video"].some((type) => scenarioData.collateral_type?.includes(type)) && (
                                            <Typography>Unsupported content type</Typography>
                                        )}
                                    </Box>
                                </Box>
                                <Box>
                                    { /*simulationHistory && simulationHistory.length > 0 && simulationHistory.map((sim, index) => (
                                            <Typography>{sim.id}</Typography>
                                    )) */}
                                    <Typography variant="h5" sx={{ mb: 2 }}>Your Previous Scores</Typography>
                                    {simulationLineChartData != null && (
                                        <>
                                            <LineChart
                                                height={500}
                                                width={500}
                                                series={[
                                                    { data: simulationLineChartData.eq_score, label: 'Total Score' },
                                                    { data: simulationLineChartData.eq_score, label: 'EQ Score' },
                                                    { data: simulationLineChartData.impact_score, label: 'Impact Score' },
                                                    { data: simulationLineChartData.super_influencer_score, label: 'Influence Score' },
                                                ]}
                                                xAxis={[{ scaleType: 'point', data: ['5th', '4th', '3rd', '2nd', 'Latest'] }]}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Box>

                        </>
                    ) : (
                        <>
                            {showCallScreen ? (
                                <CallScreen
                                    url={simulationCallURL}
                                    onDisconnect={endSimulation}
                                    scenarioData={scenarioData}
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() => setShowCallScreen(true)}
                                >
                                    Start Call
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}


        </React.Fragment>
    );
}