import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, CircularProgress, Paper, IconButton, Collapse } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getAvatarMessageWordCount = (message) => {
  const avatarMessageIndex = message.lastIndexOf("</b>");
  if (avatarMessageIndex !== -1) {
    let avatarMessage = message.substring(avatarMessageIndex + 4);
    return avatarMessage.split(/\s+/).filter(word => word.trim().length > 0).length;
  }
  return 0;
}

export const CallScreen = ({ url, onDisconnect, scenarioData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('');
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const peerConnection = useRef(null);
  const dataChannel = useRef(null);
  const unityInstance = useRef(null);
  const transcriptRef = useRef(null);
  const [videoPosition, setVideoPosition] = useState({ x: 20, y: 20 });
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false);
  const initializeUnity = async () => {
    try {

      await new Promise(resolve => setTimeout(resolve, 1000));

      if (!window.createUnityInstance) {
        throw new Error('Unity loader error');
      }

      const instance = await window.createUnityInstance(canvasRef.current, {
        dataUrl: "/avatar/Build/GuruNowBuild.data.unityweb",
        frameworkUrl: "/avatar/Build/GuruNowBuild.framework.js.unityweb",
        codeUrl: "/avatar/Build/GuruNowBuild.wasm.unityweb",
        streamingAssetsUrl: "/avatar/StreamingAssets",
        companyName: "GuruNow",
        productName: "GuruNow AI",
        productVersion: "0.1",
        matchWebGLToCanvasSize: false,
      });

      unityInstance.current = instance;
      window.unityInstance = instance;

      let avatarName = scenarioData.avatar_persona.avatar_model;

      if(!avatarName)
      {
        avatarName = "Debbie";
      }

      avatarName = avatarName.charAt(0).toUpperCase() + avatarName.slice(1);

      unityInstance.current.SendMessage("SceneLoader", "LoadScene", String("GuruNow Simulation/"+avatarName));


    } catch (error) {
      console.error('Failed to initialize Unity:', error);
      throw error;
    }
  };

  const callOnSpeech = (data) => {
    if (unityInstance.current) {
      unityInstance.current.SendMessage("AvatarManager", "OnSpeech", data);
    }
  };

  const callOnStopSpeech = (data) => {
    if (unityInstance.current) {
      unityInstance.current.SendMessage("AvatarManager", "OnStopSpeech");
    }
  };

  const triggerAvatarAnimation = (message) => {
    if (unityInstance.current) {
      unityInstance.current.SendMessage("AvatarManager", "TriggerAvatarAnimation", message);
    }
  };

  const negotiate = async () => {
    const pc = peerConnection.current;
    if (!pc) {
      throw new Error('Peer connection not initialized');
    }

    try {
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      await new Promise((resolve) => {
        if (pc.iceGatheringState === 'complete') {
          resolve();
        } else {
          const checkState = () => {
            if (pc.iceGatheringState === 'complete') {
              pc.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          }
          pc.addEventListener('icegatheringstatechange', checkState);
        }
      });

      const urlParams = new URL(url);
      const response = await fetch(`${urlParams.origin}/offer`, {
        body: JSON.stringify({
          sdp: pc.localDescription.sdp,
          type: pc.localDescription.type,
          simulation_id: urlParams?.searchParams?.get('simulation_id')
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      const answer = await response.json();
      if (pc.signalingState !== 'closed') {
        await pc.setRemoteDescription(answer);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const initializeCall = async () => {
    if (peerConnection.current) {
      cleanup();
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    try {
      const pc = createPeerConnection();
      peerConnection.current = pc;

      const dc = pc.createDataChannel('chat', { ordered: true });
      dataChannel.current = dc;
      setupDataChannelHandlers();

      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
      });

      if (pc.signalingState !== 'closed') {
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      }

      if (pc.signalingState !== 'closed') {
        await negotiate();
      }

      await initializeUnity();
      setIsLoading(false);
      

      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = videoStream;
        }
      } catch (err) {
        console.error('Could not acquire video:', err);
      }
    } catch (error) {
      console.error('Failed to initialize call:', error);
      cleanup();
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        await initializeCall();
      } catch (error) {
        if (mounted) {
          console.error('Failed to initialize:', error);
        }
      }
    };

    init();

    return () => {
      mounted = false;
      cleanup();
    };
  }, [url]);

  const createPeerConnection = () => {
    const config = {
      sdpSemantics: 'unified-plan'
    };

    const pc = new RTCPeerConnection(config);

    pc.addEventListener('icegatheringstatechange', () => {
      console.log('ICE gathering state:', pc.iceGatheringState);
    });

    pc.addEventListener('iceconnectionstatechange', () => {
      console.log('ICE connection state:', pc.iceConnectionState);
    });

    pc.addEventListener('signalingstatechange', () => {
      console.log('Signaling state:', pc.signalingState);
    });

    pc.addEventListener('track', (evt) => {
      if (evt.track.kind === 'video') {
        if (videoRef.current) {
          videoRef.current.srcObject = evt.streams[0];
        }
      } else if (evt.track.kind === 'audio') {
        let audioElement = document.getElementById('audio');
        if (!audioElement) {
          audioElement = document.createElement('audio');
          audioElement.id = 'audio';
          audioElement.autoplay = true;
          document.body.appendChild(audioElement);
        }
        audioElement.srcObject = evt.streams[0];

        const audioContext = new AudioContext();
        const mediaStream = new MediaStream([evt.track]); // Create a stream from the track
        const source = audioContext.createMediaStreamSource(mediaStream);
        const analyser = audioContext.createAnalyser();

        source.connect(analyser);

        // Function to detect audio activity
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        var spoken = false;

        function detectAudio() {
          analyser.getByteFrequencyData(dataArray);

          // Check if there's any non-zero audio data
          const hasAudio = dataArray.some((value) => value > 0);

          if (hasAudio) {
            // Call the OnSpeech endpoint in the Unity scene - pass the audio array as a string (limitation of Unity JS integration)
            callOnSpeech(dataArray.toString());
            spoken = true;

          } else {

            if (spoken) {
              // Stopping speech
              callOnStopSpeech();
              spoken = false;
            }

          }

          requestAnimationFrame(detectAudio);
        }

        // Start monitoring the audio data
        detectAudio();
      }

    });

    return pc;
  };

  const setupDataChannelHandlers = () => {
    if (!dataChannel.current) return;

    let time_start = new Date().getTime();
    let dcInterval = null;

    const current_stamp = () => {
      return new Date().getTime() - time_start;
    };

    dataChannel.current.addEventListener('open', () => {
      console.log('Data channel open');
      dcInterval = setInterval(() => {
        if (dataChannel.current?.readyState === 'open') {
          const message = 'poll ' + current_stamp();
          dataChannel.current.send(message);
        }
      }, 1000);
    });

    let currentAvatarMessage = "";
    dataChannel.current.addEventListener('message', (evt) => {
      try {
        // Attempt to parse the JSON string
        const parsedData = JSON.parse(evt.data);
        switch (parsedData.type) {
          case "transcript":
            let displayMessage = parsedData.data;

            if (!currentAvatarMessage.includes("Video call connected")) {
              setCurrentMessage(displayMessage);
              currentAvatarMessage = displayMessage;
            }

            if (evt.data.includes('END_OF_DISCUSSION')) {
              displayMessage = evt.data.replace('END_OF_DISCUSSION', '');
              setCurrentMessage(displayMessage);
              setTimeout(() => {
                handleDisconnect();
              }, 7000);
              return;
            } else if (displayMessage.length > currentAvatarMessage.length) {
              const avatarMessageIndex = displayMessage.lastIndexOf("</b>");
              let avatarMessage = displayMessage.substring(avatarMessageIndex + 4);
              //triggerSpeechAnimation(avatarMessage);
            }
            setCurrentMessage(displayMessage);
            currentAvatarMessage = displayMessage;

            break;

          case "animation":
            triggerAvatarAnimation(parsedData.data);
            break;

          case "event":

            break;

          default:
            console.error("Unknown message from RTC: ", parsedData);
            break;
        }


      } catch (error) {
        // Fall back to earlier logic
        console.error("Invalid JSON:", error.message);

        let displayMessage = evt.data;

        if (!currentAvatarMessage.includes("Video call connected")) {
          setCurrentMessage(displayMessage);
          currentAvatarMessage = displayMessage;
        }

        if (evt.data.includes('END_OF_DISCUSSION')) {
          displayMessage = evt.data.replace('END_OF_DISCUSSION', '');
          setCurrentMessage(displayMessage);
          setTimeout(() => {
            handleDisconnect();
          }, 7000);
          return;
        } else if (displayMessage.length > currentAvatarMessage.length) {
          const avatarMessageIndex = displayMessage.lastIndexOf("</b>");
          let avatarMessage = displayMessage.substring(avatarMessageIndex + 4);
          //triggerSpeechAnimation(avatarMessage);
        }
        setCurrentMessage(displayMessage);
        currentAvatarMessage = displayMessage;

      }
    });

    dataChannel.current.addEventListener('close', () => {
      console.log("Data channel closed");
      if (dcInterval) {
        clearInterval(dcInterval);
      }
    });
  };

  const handleDisconnect = () => {
    cleanup();
    onDisconnect();
  };

  const cleanup = () => {
    try {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach(track => {
          track.stop();
        });
        videoRef.current.srcObject = null;
      }

      if (dataChannel.current) {
        dataChannel.current.close();
      }

      if (peerConnection.current) {
        peerConnection.current.getSenders().forEach((sender) => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        peerConnection.current.close();
      }
    } catch (err) {
      console.error('Error during cleanup:', err);
    } finally {
      dataChannel.current = null;
      peerConnection.current = null;

      if (unityInstance.current) {
        unityInstance.current.Quit();
        unityInstance.current = null;
      }
    }
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    videoRef.current.startX = event.clientX - videoPosition.x;
    videoRef.current.startY = event.clientY - videoPosition.y;
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const newX = event.clientX - videoRef.current.startX;
      const newY = event.clientY - videoRef.current.startY;

      setVideoPosition({ x: newX, y: newY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTranscript = () => {
    setOpen(true);

    setTimeout(() => {
      transcriptRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);

    if (!open) {
      setTimeout(() => {
        transcriptRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          width: "960px",
          height: "600px",
          borderRadius: 2,
          backgroundColor: "#231F20",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >

        <Box
          sx={{
            position: "absolute",
            bottom: '1rem',
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            margin: '0 2rem',
            borderRadius: 20,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 100,
            color: "white",
          }}
        >
          <IconButton
            onClick={handleTranscript}
            sx={{
              color: "white",
              '&:hover': {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              marginLeft: 2,
            }}
          >
            <ArticleIcon />
          </IconButton>
          <Button
            variant="contained"
            color="error"
            onClick={handleDisconnect}
            sx={{
              marginRight: 2,
              backgroundColor: "#d82c21",
              '&:hover': {
                backgroundColor: "#b71c1c",
              },
            }}
          >
            End Simulation
          </Button>
        </Box>

        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress />
            <Typography variant="h6" color="white" mt={2}>
              Connecting video call, please wait...
            </Typography>
          </Box>
        )}
        <canvas
          ref={canvasRef}
          id="unity-canvas"
          width={960}
          height={600}
          tabIndex={-1}
          style={{
            display: isLoading ? "none" : "block",
          }}
        />
        <video
          ref={videoRef}
          id="video"
          autoPlay
          playsInline
          muted
          style={{
            position: "absolute",
            top: videoPosition.y,
            left: videoPosition.x,
            width: "25%",
            cursor: isDragging ? "grabbing" : "grab",
            border: "1px solid #333",
            zIndex: 15,
          }}
          onMouseDown={handleMouseDown}
        />
      </Paper>

      <Box sx={{ width: "100%", maxWidth: "960px", marginTop: 2 }}>
        {/* Toggle Button */}
        <Button
          onClick={handleToggle}
          variant="contained"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            textTransform: "none",
            backgroundColor: "#1976d2",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          <Typography variant="h6" color="white">Transcript</Typography>
          <ExpandMoreIcon
            sx={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          />
        </Button>

        {/* Collapsible Transcript Content */}
        <Collapse in={open}>
          <Box
            ref={transcriptRef}
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              padding: 1,
              height: "200px",
              overflowY: "auto",
              backgroundColor: "white",
            }}
            dangerouslySetInnerHTML={{ __html: currentMessage }}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default CallScreen;
